import React from 'react'

import { StaticImage } from "gatsby-plugin-image";

import LogoCloud from '../LogoCloud';
import SiteNavigation from './navigation';

function SiteHeader() {
    return (
        <div>
            
            <div className="relative bg-white overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="relative z-10 pb-8 bg-white bg-opacity-95 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                        <svg
                            className="hidden lg:block absolute -left-1/3 inset-y-0 h-full w-48 text-white transform translate-x-1/2 opacity-95"
                            fill="currentColor"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="50,0 66.7,0 66.7,150 0,100" />
                        </svg>
                        <svg
                            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2 opacity-95"
                            fill="white"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="50,0 100,0 50,100 50,100" />
                        </svg>

                        <SiteNavigation bgColor="bg-transparent" />
                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                                    <span className="block xl:inline">Vi er eksperter på</span>{' '}
                                    <span className="block text-convivial xl:inline">bortgjemte data</span>
                                </h1>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Vi tror at morgendagens vinnere vil være de som tør å åpne opp, tilgjengeliggjøre bortgjemte data og ta i bruk ny teknologi for å skape de
                                    beste&nbsp;brukeropplevelsene.
                                </p>
                                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <div className="rounded-md shadow sm:w-48">
                                        <a
                                            href="/om"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-convivial hover:bg-opacity-60 md:py-4 md:text-lg md:px-10"
                                        >
                                            Les mer
                                        </a>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-3 sm:w-48">
                                        <a
                                            href="/om/#kontakt"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-convivial bg-transparent hover:bg-gray-50 ring-2 ring-convivial md:py-4 md:text-lg md:px-10"
                                        >
                                            Kontakt oss
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:left-0">
                    <StaticImage
                        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                        alt="bortgjemte data"
                        src="../../../static/Hero/BannerImage5.jpg"
                    />
                </div>
            </div>
            <LogoCloud bgColor="bg-transparent" product={['generic']} />
        </div>
    );
}

export default SiteHeader;