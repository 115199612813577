import React from "react";

interface ISectionHeader {
  title: string;
  header: string;
  description: string;
  backgroundColor: string;
}

export default function SectionHeader(props: ISectionHeader) {
  return (
    <div className={props.backgroundColor}>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-gray-500 tracking-wide uppercase">{props.title}</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl font-header">
            {props.header}
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {props.description}
          </p>
        </div>
      </div>
    </div>
  );
}
