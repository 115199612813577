import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import ConsultantFeature from '../components/ConsultantFeature';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import KinetikFeature from '../components/KinetikFeature';
import ProjectFeature from '../components/ProjectFeature';
import SectionHeader from '../components/SectionHeader';
import SiteHeader from '../components/SiteHeader/type4';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function AlertWidget({ mode }) {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const _script = document.createElement('script');
        _script.src = 'https://webalertwidget.grid.kinetik.no/index.js';
        _script.defer = true;
        _script.addEventListener('load', () => setScriptLoaded(true));
        document.body.appendChild(_script);
    }, []);

    return (
        <div className="kinetikgrid_widget" data-mode={mode} data-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlZjM4ZDgwOC0zMzUwLTQzYTQtOTM3MC05NGUzYTgxMDVkMmUiLCJuYW1laWQiOiJlNTE5NzlhMi1mOTNmLTRhZjctOTA4Ny1jZmVjN2RjMWZlNzYiLCJleHAiOjI1MzQwMjMwMDgwMCwiaXNzIjoiQ29udml2aWFsLkFnZW50QXBpIiwiYXVkIjoiQ29udml2aWFsLkN1c3RvbWVyLldlYiJ9.kgcF9Ms0DFqtTb3xTvwk3V8pAznel7kLnOWCiJmSUCo"></div>
    );
}

function Index({ props }) {
    return (
        <div className="bg-white">
            <Helmet>
                <title>Convivial - Varsler</title>
                <link rel="canonical" href="https://convivial.no" />
                <meta name="description" content="Eksperter på bortgjemte data" />
                <link rel="stylesheet" href="https://webalertwidget.grid.kinetik.no/index.css" />
            </Helmet>

            <main>
                <AlertWidget mode="bar" />
                <SiteHeader />

                <SectionHeader
                    title="Varsler"
                    header="Eksempler på varsler"
                    description="Varsler sendt gjennom kinetik grid som eksempler på hvordan det ser ut på web."
                    backgroundColor="bg-gray-100"
                />
                <div className="bg-gray-100">
                    <div className="max-w-7xl mx-auto">
                        <AlertWidget mode="tiles" />
                    </div>
                </div>
                <CTA header="Kan vi hjelpe deg med ditt&nbsp;prosjekt?" subHeader="Ta kontakt!" bgColor="bg-gray-100" contactButtonColor="bg-convivial" />

            </main>
            <Footer />
        </div>
    );
}

export default Index;
